import * as React from 'react'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import SP63Chart from '../components/SP63/SP63Chart'
import SP63Table from '../components/SP63/SP63Table'
//import InsufficientData from '../components/InsufficientData'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import {
  WHO_LOCATION_OPTIONS_ALT,
  INC_LOCATION_OPTIONS_ALT,
  UNI_LOCATION_OPTIONS_ALT
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'

const Sp63Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const regionLabel = t('global')
  //Only showing GLOBAL and country data for this indicator
  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS_ALT}
          incOptions={INC_LOCATION_OPTIONS_ALT}
          uniOptions={UNI_LOCATION_OPTIONS_ALT}
          sectionId={sectionId}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <SP63Chart
              regions={data.global.nodes}
              title={`${content.chartTitle}—${regionLabel}`}
            />
          </ImageExport>
          <SP63Chart
            regions={data.global.nodes}
            title={`${content.chartTitle}—${regionLabel}`}
          />
          <SP63Table regions={data.global.nodes} />
        </>
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Sp63Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleSp63CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    global: allRegions(
      filter: { IA2030_IND: { eq: "SP6.3" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
  }
`
// global: googleSp63Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   decreasedExp20182019
//   decreasedExp20192020
//   decreasedExp20202021
//   decreasedExp20212022
//   increasedExp20182019
//   increasedExp20192020
//   increasedExp20202021
//   increasedExp20212022
//   stableExp20182019
//   stableExp20192020
//   stableExp20202021
//   stableExp20212022
// }
